export class OpenAlertsModel {
    alertID: string;
    source: string;
    alertType: string;
    measure: string;
    description: string;
    severitydesc: string;
    dateCreated: string;
    stat: string;
    owner: string;
    rownum: string;
}
