export class AgentJournalsModel {
    Id: string;
    OfficeId: number;
    OfficeName: string;
    Username: string;
    FullName: string;
    Timestamp: string;
    Content: string;
    Latest: boolean;
    Files: string[];
}
