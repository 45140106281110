export class EntityContactModel {
    name = "";
    entity = "";
    entityID: string;
    email = "";
    phone1 = "";
    phone2 = "";
    addr1 = "";
    addr2 = "";
    addr3 = "";
    addr4 = "";
    city = "";
    state = "";
    zip = "";
    internal = false;
    doNotCall = false;     
}
