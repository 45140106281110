export class EntityModel {
    type: string;
    entityId: string;
    name: string;
    addr1: string;
    addr2: string;
    addr3: string;
    addr4: string;
    city: string;
    state: string;
    zip: string;
    stat: string;
    netPermium: number;
    cpl: string;
    favorite = "no";
    rowNum: string;
    sysfavoriteID: string;
    nprPlanned: string;
    nprActual: string;
    nprPercent: string;
    nprPlannedYTD: string;
    nprPercentYTD: string;
    agentImg: string;
    email: string;
    agentNameList: string;
    noOfPeople: number;
}
