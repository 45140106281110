'use strict';
import * as paths from './paths';
export const HOME_SCREEN_ARRAY = [
    {
        "path": paths.PATH_SEARCH,
        "name": "Search"
    },
    {
        "path": paths.PATH_TOP_AGENTS,
        "name": "Top Agents"
    },
    {
        "path": paths.PATH_AGENTS_WITH_ALERT,
        "name": "Agent With Alert"
    },
    {
        "path": paths.PATH_AGENTS_WITH_PERFORMANCE,
        "name": "Agent With Performance"
    },
    {
        "path": paths.PATH_AGENTS_UNDER_PLAN,
        "name": "Agent Under Plan"
    },
    {
        "path": paths.PATH_NEWS,
        "name": "News"
    },
    {
        "path": paths.PATH_RECENT_RPOFILES,
        "name": "Recent Profile"
    },
    {
        "path": paths.PATH_FOLLOWING,
        "name": "Following"
    }
];

export const SEARCH_IN_ARRAY = ["ALL", "Agents", "Employee", "Person"];

export const NUMBER_OF_ITEMS_LOAD_ARRAY = ["5", "10", "15", "20"];

export const NEWS_CHANNEL_ARRAY = ["Google", "Yahoo", "CNBC"];
