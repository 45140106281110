export class UserProfileModel {
    uid = "";
    name = "";
    jobTitle = "";
    picture = "";
    addr1 = "";
    addr2 = "";
    city = "";
    state = "";
    zip = "";
    phoneType = "";
    phone = "";
    phone2Type = "";
    phone2 = "";
    email = "";
    shareable = false;
}
