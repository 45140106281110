export class NotesModel {

    agentID: string;
    category: string;
    categoryDesc: string;
    edited: string;
    noteDate: string;
    noteType: string;
    notes: string;
    seq: string;
    subject: string;
    typeDesc: string;
    uid: string;
    rowNum: string;
    userName: string;
    UID: string;
    sysNoteID: string;
    entity: string;
}
