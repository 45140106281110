export class ObjectiveModel {
    objectiveID: string;
    entity: string;
    entityID: string;
    UID: string;
    Stat: string;
    dueDate: string;
    type: string;
    createDate: string;
    private: string;
    description: string;
}
