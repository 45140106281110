export class AgentInfoModel {
    agentImg = "";
    rowNum: string;
    agentId: string;
    name: string;
    stat: string;
    city: string;
    state: string;
    netPermium: string;




    // agentImg: string;
    // agentName: string;
    // agentAddress: string;
    // agentNetPremium: string;
}
