import { Component, OnInit, Injector, OnDestroy } from "@angular/core";
import { AuditModels } from "../../../models/audit-models";
import { EntityModel } from "../../../models/entity-model";
import { BaseClass } from "../../../global/base-class";

@Component({
    selector: "app-audit-detail-completed",
    templateUrl: "./audit-detail-completed.component.html",
    styleUrls: ["./audit-detail-completed.component.css"],
})
export class AuditDetailCompletedComponent extends BaseClass implements OnInit, OnDestroy {
    constructor(private injector: Injector) {
        super(injector);
    }

    auditModel: AuditModels;
    entityModel: EntityModel;
    sectionScoreArray: string[];
    compassDocuments = [];
    auditID: string;
    showReports: boolean = false;
    downloadFileItem: any;

    ngOnInit() {
        this.commonFunctions.showLoadedItemTagOnHeader([], "", true);
        this.entityModel = JSON.parse(
            sessionStorage.getItem(this.constants.ENTITY_INFO)
        );
        this.auditModel = JSON.parse(
            sessionStorage.getItem(this.constants.SELECTED_AUDIT)
        );

        this.compassDocuments =  JSON.parse(
            sessionStorage.getItem(this.constants.SELECTED_AUDIT_REPO_FILES)
        )
        
        this.auditID = this.auditModel.auditID;
        this.sectionScoreArray = this.auditModel.sectionscore.split(",");
        
        if(!this.compassDocuments){
            this.getRepositoryFilesList();
        } else {
            this.showReports = this.compassDocuments.length > 0;
        }
        
        
    }
    showLessBtn(event) {
        const button = event.target || event.srcElement || event.currentTarget;
        if (button.value === "SHOW MORE") {
            button.value = "SHOW LESS";
        } else {
            button.value = "SHOW MORE";
        }
        this.commonFunctions.printLog(button);
    }

    showLessBtnOther(event) {
        const button = event.target || event.srcElement || event.currentTarget;
        if (button.value === "SHOW MORE") {
            button.value = "SHOW LESS";
            document.getElementById("tableFixedHeightId").style.height = "auto";
        } else {
            button.value = "SHOW MORE";
            document.getElementById("tableFixedHeightId").style.height = "60px";
        }
        this.commonFunctions.printLog(button);
    }

    onSendEmailConfirmationClick() {
        if (
            !this.myLocalStorage.getValue(
                this.constants.DONT_SHOW_SEND_EMAIL_DIALOG
            )
        ) {
            this.openDialogService
                .showSendMailConfirmationDialog(this.auditModel.auditor)
                .afterClosed()
                .subscribe((sendMail) => {
                    if (sendMail) {
                        this.commonFunctions.doEmail(this.auditModel.uid);
                    }
                });
        } else {
            this.commonFunctions.doEmail(this.auditModel.uid);
        }
    }


    onClickDownloadFile(item) {
        this.downloadFileItem = item;
        downloadAuditPdfApiCall(this);
    }

    goBack() {
        this.commonFunctions.backPress();
    }

    onSuccess(response) {
        this.compassDocuments = [];
        const docs = response["CompassDocument"];
        docs.forEach((element) => {
            this.compassDocuments.push(element);
        });
        this.showReports = this.compassDocuments.length > 0;

        sessionStorage.setItem(this.constants.SELECTED_AUDIT_REPO_FILES, JSON.stringify(this.compassDocuments));
    }

    onError(errorCode: any, errorMsg: string) {
        this.commonFunctions.showErrorSnackbar(errorMsg);
    }

    getRepositoryFilesList() {
        this.apiHandler.getRepositoryFilesList("Audit", this.auditID, false, this);
    }

    downloadFile(response) {
        var blob = new Blob([response.body], { type: "application/pdf" });
        var url = window.URL.createObjectURL(blob);

        var a = document.createElement("a");
        a.href = url;

        // Set the desired file name here
        a.download = this.downloadFileItem.name; // Example file name

        // Programmatically click the anchor element to trigger the download
        a.click();
        
        window.open(url, "_blank", "location=yes,menubar=yes,toolbar=yes");

        // Release the object URL after the download starts
        window.URL.revokeObjectURL(url);
    }

    ngOnDestroy(): void {
        if(this.compassDocuments && this.compassDocuments.length > 0){
            sessionStorage.removeItem(this.constants.SELECTED_AUDIT_REPO_FILES);
        }        
    }    
}

function downloadAuditPdfApiCall(context: AuditDetailCompletedComponent): void {
    context.apiHandler.downloadRepositoryFile(
        context.downloadFileItem.identifier,
        context.downloadFileItem.name,
        {
            onSuccess: (response) => {
                context.downloadFile(response);
            },
            onError: (errorCode: number, errorMsg: string) => {
                this.commonFunctions.showErrorSnackbar(errorMsg);
            },
        }
    );
}
