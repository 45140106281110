export class SentimentModel {
    createDate: string;
    notes: string;
    objectiveID: string;
    personID: string;
    personName: string;
    sentimentID: string;
    type: string;
    uid: string;
    objective: string;
}
